const host = process.env.REACT_APP_BOOKINGAPI_HOST;

export default function useApi() {
  function get<T>(path: string): Promise<T | null> {
    return fetch(`${host}${path}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    }).then((rsp) => {
      if (rsp.status === 404) {
        return null;
      }

      if (!rsp.ok) {
        throw new Error('Failed to retrieve booking');
      }

      return rsp.json();
    });
  }

  function post<T>(path: string, data: any): Promise<T> {
    return fetch(`${host}${path}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then((rsp) => {
      if (!rsp.ok) {
        throw new Error('Failed to retrieve booking');
      }

      return rsp.json();
    });
  }

  function patch<T>(path: string, data: any): Promise<T> {
    return fetch(`${host}${path}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/merge-patch+json'
      },
      body: JSON.stringify(data),
    }).then((rsp) => {
      if (!rsp.ok) {
        throw new Error('Failed to retrieve booking');
      }

      return rsp.json();
    });
  }

  return { get, post, patch };
}
