import React, { ReactNode, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { PointOfSale } from "../model";

type AuthData = {
  pointOfSale: PointOfSale;
};

type AuthContextProps = {
  pointOfSale?: PointOfSale;
  login: (arg0: PointOfSale) => void;
  logout: () => void;
};

const AuthContext = React.createContext<AuthContextProps>({
  login: () => {
  },
  logout: () => {
  },
});

export function useAuth(): AuthContextProps {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: {
  children: ReactNode,
}) {
  const [authData, setAuthData] = useLocalStorage<AuthData | undefined>('auth_data');

  const value = {
    pointOfSale: authData?.pointOfSale,
    login: (pointOfSale) => {
      setAuthData({
        pointOfSale,
      });
    },
    logout: () => {
      setAuthData(undefined);
    },
  } as AuthContextProps;

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>;
}
