import React, { useState } from "react";
import logo from "../res/img/logo.svg";
import Dialog from "../layout/Dialog";

import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { ImCamera, ImCheckmark, ImUser } from "react-icons/im";
import { Html5Qrcode } from "html5-qrcode";
import useLocalStorage from "../hooks/useLocalStorage";
import { useAuth } from "../contexts/AuthContext";
import useApi from "../hooks/useApi";
import { PointOfSale } from "../model";
import ScannerButton from "../components/ScannerButton";

function LoginPage() {
  const [cameraId, setCameraId] = useLocalStorage<string | undefined>('camera-id');

  return (
    <Dialog title="Swiss Activities Ticket Scan">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={0}
        pagination
        navigation
        slidesPerView={1}
      >
        <SwiperSlide>
          <WelcomeSlide/>
        </SwiperSlide>
        <SwiperSlide>
          <CameraPermissionsSlide cameraId={cameraId} setCameraId={setCameraId}/>
        </SwiperSlide>
        {cameraId && (
          <SwiperSlide>
            <ScanLoginCodeSlide/>
          </SwiperSlide>
        )}
      </Swiper>
    </Dialog>
  );
}

function WelcomeSlide() {
  return (
    <div className="m-6">
      <div className="mb-3 has-text-centered">
        <img src={logo} alt="Swiss Activities Logo" width={150}/>
      </div>
      <div className="box">
        <h2 className="title is-5">Hallo!</h2>
        <div className="block">
          Du verkaufst Tickets via Swiss Activities und möchtest diese
          validieren können? Dann bist du hier genau richtig.
        </div>
        <div className="block">
          Alles was du brauchst ist ein Gerät mit Kamera (Smartphone, Tablet, Computer mit Webcam, etc.)
          und den Login QR-Code, den wir dir zugestellt haben.
        </div>
        <div className="block">
          Jetzt einmal kurz mit dem Finger von rechts nach links swipen und es kann auch schon mit der
          Einrichtung los gehen.
        </div>
      </div>
    </div>
  );
}

function CameraPermissionsSlide({ cameraId, setCameraId }: {
  cameraId?: string,
  setCameraId: (arg0: string) => void,
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const onRequestCameraPermission = () => {
    setIsLoading(true);
    Html5Qrcode.getCameras().then((devices) => {
      if (devices.length === 0) {
        throw new Error('No camera');
      }
      setCameraId(devices[0].id);
    }).catch(() => {
      setError('Es konnte keine Kamera gefunden werden.');
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div className="m-6">
      <ImCamera size={40} className="has-text-white"/>
      <div className="box">
        <h2 className="title is-5">Kamerazugriff erlauben</h2>
        <div className="block">
          Damit du Tickets scannen kannst, musst du ein Gerät mit Kamera
          nutzen und wir auf diese zugreifen können.
        </div>

        {error && (
          <div className="notification is-danger is-light">{error}</div>
        )}

        <div className="block">
          {cameraId ? (
            <div className="has-text-success has-text-centered">
              <ImCheckmark size={40}/>
              <div className="has-text-weight-bold mt-1">Kamera gefunden</div>
            </div>
          ) : (
            <div className="has-text-centered">
              <button className={`button is-dark is-rounded is-small ${isLoading ? 'is-loading' : ''}`} disabled={isLoading} onClick={onRequestCameraPermission}>
                Kamera suchen
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ScanLoginCodeSlide() {
  const { login } = useAuth();
  const { get } = useApi();
  const [error, setError] = useState<string>('');

  const attemptLogin = (code: string) => {
    get<PointOfSale>('/points_of_sale/by_code/' + code)
    .then((data) => {
      if (data === null) {
        throw new Error('404');
      }
      login(data);
    })
    .catch(() => {
      setError(`Point of Sale "${code}" wurde nicht gefunden. Ist der QR Code noch gültig?`);
    });
  };

  return (
    <div className="m-6">
      <ImUser size={40} className="has-text-white"/>
      <div className="box">
        <h2 className="title is-5">Anmelden</h2>
        <div className="block">
          Damit wir sicherstellen können, dass du nur Tickets
          deines Anbieters scannen kannst, musst du nun den
          Login QR-Code scannen, den du von uns erhalten hast.
        </div>

        {error && (
          <div className="notification is-danger is-light">{error}</div>
        )}

        <div className="block">
          <ScannerButton onCodeScanned={attemptLogin} onError={(error) => setError(error)}/>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;