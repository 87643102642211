import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";

import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import ScannerPage from "./pages/ScannerPage";

function App() {
  return (
    <AuthProvider>
      <Router/>
    </AuthProvider>
  );
}

function Router() {
  const { pointOfSale } = useAuth();

  if (!pointOfSale) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LoginPage}/>
          <Route path="/">
            <Redirect to="/"/>
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/ticket/:ticketId" exact component={ScannerPage}/>
        <Route path="/logout" exact component={LogoutPage}/>
        <Route path="/" exact component={ScannerPage}/>
        <Route path="/">
          <Redirect to="/"/>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
