import React from "react";
import { useAuth } from "../contexts/AuthContext";
import Layout from "../layout/Layout";
import { ImUser } from "react-icons/im";

function LogoutPage() {
  const { pointOfSale, logout } = useAuth();

  const onLogout = () => {
    logout();
  };

  return (
    <Layout paddingless>
      <div className="logout-page">
        <div className="m-6">
          <ImUser size={40} className="has-text-white"/>
          <div className="box">
            <h2 className="title is-5">Abmelden</h2>
            <div className="block">
              Wirklich von "{pointOfSale!.label}" abmelden?
            </div>
            <div className="block">
              Der Login QR-Code muss erneuet gescannt werden, wenn du dich danach wieder anmelden möchtest.
            </div>

            <div className="block has-text-centered">
              <button className="button is-dark is-rounded is-outlined is-small" onClick={onLogout}>Abmelden</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default LogoutPage;