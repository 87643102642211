import React, { useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { ImQrcode } from "react-icons/im";

function ScannerButton({ onCodeScanned, onError }: {
  onCodeScanned: (code: string) => void,
  onError: (error: string) => void,
}) {
  const [scanner, setScanner] = useState<Html5Qrcode>();
  const { width } = useWindowDimensions();

  const containerId = 'qr-code-scanner';

  const onScanStart = () => {
    const scanner = new Html5Qrcode(containerId);

    scanner.start(
      { facingMode: "environment" },
      {
        fps: 10,
        qrbox: Math.floor(width / 3 * 2),
      },
      (decodedText) => {
        scanner.stop().then(() => {
          onCodeScanned(decodedText);
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          setScanner(undefined);
        });
      },
      () => {
      },
    ).catch(() => {
      onError('Es ist ein Fehler aufgetreten');
      setScanner(undefined);
    });

    setScanner(scanner);
  };

  const onCancelScanning = () => {
    if (scanner && scanner.isScanning) {
      scanner.stop().catch(() => {
      }).finally(() => {
        setScanner(undefined);
      });
    }
  };

  return (
    <>
      {!scanner && (
        <div className="has-text-centered">
          <button className={`button is-dark is-uppercase is-rounded is-small ${scanner ? 'is-loading' : ''}`} disabled={!!scanner} onClick={onScanStart}>
            <ImQrcode size={20} className="mr-2"/> Code scannen
          </button>
        </div>
      )}

      <div className={`fullscreen-scanner ${scanner ? '' : 'is-inactive'}`}>
        <div className="scanner">
          <h3 className="title is-5 mb-3">QR Code scannen</h3>
          <div id={containerId}/>
          <div className="has-text-centered mt-3">
            <button className="button is-dark is-rounded is-outlined is-small" onClick={onCancelScanning}>Abbrechen</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScannerButton;