export type PointOfSale = {
  pointOfSaleId: string,
  label: string,
  code: string,
};

export enum TicketValidationStatus {
  CheckedIn = 'checked_in',
  CheckedOut = 'checked_out',
  Rejected = 'rejected',
  Unrejected = 'unrejected',
};

export type Ticket = {
  ticketId: string,
  externalTicketReference: string | null,
  customerName: string,
  date: string,
  isAllDay: boolean,
  seatPrice: SeatPrice,
  occupancy: number,
  checkedInAt?: string,
  rejectedAt?: string,
  validationStatus: TicketValidationStatus,
  validations: TicketValidation[],
};

export type TicketValidation = {
  ticketValidationId: string,
  pointOfSale: {
    label: string,
  },
  validatedAt: string,
  status: TicketValidationStatus,
}

export type SeatPrice = {
  currency: string,
  amount: string,
  formatted: string,
};