import React, { Fragment } from "react";

interface DateTimeFormatOptions {
  localeMatcher?: "best fit" | "lookup";
  weekday?: "long" | "short" | "narrow";
  era?: "long" | "short" | "narrow";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  timeZoneName?: "long" | "short";
  formatMatcher?: "best fit" | "basic";
  hour12?: boolean;
  timeZone?: string;
}

export function DateTime({ date, format }: {
  date: string,
  format?: DateTimeFormatOptions,
}) {
  const formatted = new Intl.DateTimeFormat('de-CH', format).format(new Date(date));

  return <Fragment>{formatted}</Fragment>
}


export function Heading1({title}: {title: string}) {
  return (
    <h1 className="title is-3 is-uppercase has-text-centered mt-3">{title}</h1>
  );
}