import React, { ReactNode } from "react";
import Navbar from "./Navbar";

function Layout({ children, centered = false, paddingless = false }: {
  children: ReactNode,
  centered?: boolean,
  paddingless?: boolean,
}) {
  return (
    <>
      <Navbar/>
      
      <main className={centered ? 'centered' : ''}>
        <div className={`container is-fluid ${paddingless ? 'p-0' : 'p-3'}`}>
          {children}
        </div>
      </main>
    </>
  );
}

export default Layout;