import React, { ReactNode } from "react";

export type Values = {
  label: ReactNode | string,
  value: ReactNode | string,
}[];

function DefinitionList({ values }: {
  values: Values,
}) {
  return (
    <div>
      {values.map(({ label, value }, idx) => (
          <div key={idx} className="block">
            <div className="heading">{label}</div>
            <div className="has-text-weight-semibold">{value}</div>
          </div>
        )
      )}
    </div>
  );
}

export default DefinitionList;