import React, { ReactNode } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { useHistory } from "react-router-dom";

function Dialog({ title, backButton = false, children }: { title?: string, backButton?: boolean, children: ReactNode }) {
  const history = useHistory();

  return (
    <main>
      <nav className="navbar is-fixed-top is-primary" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          {backButton && (
            <a role="button" className="navbar-item" onClick={() => history.push('/')}>
              <ImArrowLeft2/>
            </a>
          )}
          <div className="navbar-item sa">
            {title}
          </div>
        </div>
      </nav>
      {children}
    </main>
  );
}

export default Dialog;