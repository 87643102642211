import { Link } from "react-router-dom";
import logo from "../res/img/logo.svg";
import { useState } from "react";

function Navbar() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  return (
    <nav className="navbar is-fixed-top is-primary" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img src={logo} alt="Swiss Activities Logo" height="28"/>
        </Link>
        <Link className="navbar-item sa" to="/">
          Swiss Activities
        </Link>

        <a role="button" className={`navbar-burger ${isOpen ? 'is-active' : ''}`}
           aria-label="menu" aria-expanded="false"
           data-target="mainNav" onClick={() => setIsOpen(!isOpen)}>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
        </a>
      </div>

      <div id="mainNav" className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
        <div className="navbar-start">
          <Link className="navbar-item" to="/logout">Abmelden</Link>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;