import React, { Fragment, useEffect, useMemo, useState } from "react";
import Layout from "../layout/Layout";
import { useAuth } from "../contexts/AuthContext";
import ScannerButton from "../components/ScannerButton";
import { Ticket, TicketValidationStatus } from "../model";
import useApi from "../hooks/useApi";
import DefinitionList, { Values } from "../components/DefinitionList";
import QRCode from "react-qr-code";
import { DateTime } from "../widgets";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { ImBlocked, ImCheckmark, ImQrcode, ImUndo, ImWarning } from "react-icons/im";

function ScannerPage() {
  const [error, setError] = useState<string>('');
  const { pointOfSale } = useAuth();
  const { get, patch } = useApi();
  const [codeNotFound, setCodeNotFound] = useState<string>('');
  const [ticket, setTicket] = useState<Ticket>();
  const { width: windowWidth } = useWindowDimensions();

  let qrCodeSize = windowWidth / 4;
  if (qrCodeSize > 250) {
    qrCodeSize = 250;
  }

  const retrieveTicket = (code: string) => {
    setCodeNotFound('');
    setTicket(undefined);

    get<Ticket>('/tickets/by_id_or_reference/' + code)
    .then((data) => {
      if (data === null) {
        setCodeNotFound(code);
      } else {
        setTicket(data);
      }
    })
    .catch(() => {
    });
  };

  const onCheckInTicket = () => {
    patch<Ticket>(`/tickets/${ticket!.ticketId}/check_in`, { pointOfSaleCode: pointOfSale!.code })
    .then((data) => {
      setTicket(data);
    })
    .catch(() => {
      setError('Check-In hat leider nicht funktioniert.');
    });
  };

  const onCheckOutTicket = () => {
    patch<Ticket>(`/tickets/${ticket!.ticketId}/check_out`, { pointOfSaleCode: pointOfSale!.code })
    .then((data) => {
      setTicket(data);
    })
    .catch(() => {
      setError('Check-Out hat leider nicht funktioniert.');
    });
  };

  const onRejectTicket = () => {
    patch<Ticket>(`/tickets/${ticket!.ticketId}/reject`, { pointOfSaleCode: pointOfSale!.code })
    .then((data) => {
      setTicket(data);
    })
    .catch(() => {
      setError('Ablehnung hat leider nicht funktioniert.');
    });
  };

  const onUnrejectTicket = () => {
    patch<Ticket>(`/tickets/${ticket!.ticketId}/unreject`, { pointOfSaleCode: pointOfSale!.code })
    .then((data) => {
      setTicket(data);
    })
    .catch(() => {
      setError('Aufhebung der Ablehnung hat leider nicht funktioniert.');
    });
  };

  const ticketStatus = useMemo<TicketValidationStatus>(() => {
    return ticket ? ticket.validationStatus : TicketValidationStatus.CheckedOut;
  }, [ticket]);

  const backgroundTicketStatus = useMemo<string>(() => {
    if (ticketStatus === TicketValidationStatus.CheckedIn) {
      return 'has-background-success-dark';
    }

    if (ticketStatus === TicketValidationStatus.Rejected) {
      return 'has-background-danger-dark';
    }

    return 'has-background-grey-lighter';
  }, [ticketStatus]);

  const ticketValues = useMemo(() => {
    if (!ticket) {
      return [];
    }

    const values: Values = [];

    if (ticket.validations.length > 0) {
      values.push({
        label: 'Check-In Verlauf',
        value: (
          <div className="is-size-7">
            {ticket.validations.map((validation) => {
              const date = <DateTime date={validation.validatedAt}
                                     format={{ day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: '2-digit' }}/>;

              switch (validation.status) {
                case TicketValidationStatus.CheckedIn:
                  return <div key={validation.ticketValidationId}>{date}: Eingecheckt via "{validation.pointOfSale.label}"</div>;
                case TicketValidationStatus.CheckedOut:
                  return <div key={validation.ticketValidationId}>{date}: Ausgecheckt via "{validation.pointOfSale.label}"</div>;
                case TicketValidationStatus.Rejected:
                  return <div key={validation.ticketValidationId}>{date}: Abgelehnt via "{validation.pointOfSale.label}"</div>;
                case TicketValidationStatus.Unrejected:
                  return <div key={validation.ticketValidationId}>{date}: Ablehnung aufgehoben via "{validation.pointOfSale.label}"</div>;
                default:
                  return <Fragment key={validation.ticketValidationId}/>
              }
            })}
          </div>
        ),
      });
    }

    return values.concat([
      { label: 'Ticket ID', value: ticket.ticketId },
      { label: 'Externe Referenz', value: ticket.externalTicketReference },
      { label: 'Kundenname', value: ticket.customerName },
      {
        label: 'Datum',
        value: <DateTime date={ticket.date} format={{ weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' }}/>
      },
      {
        label: 'Uhrzeit',
        value: ticket.isAllDay ? 'Ganztags' : (
          <><DateTime date={ticket.date} format={{ hour: 'numeric', minute: '2-digit' }}/> Uhr</>
        )
      },
    ]);
  }, [ticket]);

  /*useEffect(() => {
    retrieveTicket('tix_57313dba951b');
  }, []);*/

  return (
    <Layout paddingless>
      <div className="layout-subtitle">
        <div>
          <h2 className="title is-5 has-text-white">{pointOfSale!.label}</h2>
        </div>
        <div>
          <ScannerButton onCodeScanned={retrieveTicket} onError={(error) => setError(error)}/>
        </div>
      </div>

      {ticket ? (
        <div>
          <div className={`is-flex is-align-items-center is-justify-content-space-around py-6 p-3 ${backgroundTicketStatus}`}>
            <div>
              {ticketStatus === TicketValidationStatus.CheckedIn ? (
                <button className="button is-light is-outline is-rounded is-medium" onClick={onCheckOutTicket}>
                  <ImUndo size={24}/>
                </button>
              ) : (
                <button disabled={ticketStatus === TicketValidationStatus.Rejected} className="button is-danger is-outline is-rounded is-medium" onClick={onRejectTicket}>
                  <ImBlocked size={24}/>
                </button>
              )}
            </div>
            <div className="box p-3 mb-0">
              <QRCode value={getQrCodeValue(ticket)} size={qrCodeSize}/>
            </div>
            <div>
              {ticketStatus === TicketValidationStatus.Rejected ? (
                <button className="button is-light is-outline is-rounded is-medium" onClick={onUnrejectTicket}>
                  <ImUndo size={24}/>
                </button>
              ) : (
                <button disabled={ticketStatus === TicketValidationStatus.CheckedIn} className="button is-success is-outline is-rounded is-medium" onClick={onCheckInTicket}>
                  <ImCheckmark size={24}/>
                </button>
              )}
            </div>
          </div>
          <div className="p-3">
            <DefinitionList values={ticketValues}/>
          </div>
        </div>
      ) : (
        <div className="ready-to-scan">
          <div>
            {codeNotFound !== '' && (
              <div className="has-text-danger">
                <ImWarning size={windowWidth / 2}/>
                <div className="notification is-danger is-light">Es wurde kein Ticket mit Code "{codeNotFound}" gefunden</div>
              </div>
            )}

            {error !== '' && (
              <div className="notification is-danger is-light">{error}</div>
            )}

            {codeNotFound === '' && error === '' && (
              <>
                <ImQrcode size={windowWidth / 2}/>
                <div className="is-uppercase is-size-5">Bereit zum Scannen</div>
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}

function getQrCodeValue(ticket: Ticket) {
  return ticket.externalTicketReference || ticket.ticketId;
}

export default ScannerPage;